import m, { Duration, Moment } from 'moment/moment';

import { DTO, Entity, MapFromDTO, Mapper, momentMapper, Sortable } from '@bp/shared/models/metadata';

import { CreditPackageStatus } from './credit-package-status';

export type CreditPackageBaseDto = Omit<DTO<CreditPackageBase>, 'duration'>
	& { duration?: Duration | string };

export class CreditPackageBase extends Entity {

	override name = 'Credit Package';

	@MapFromDTO()
	merchantId!: string;

	@Mapper(CreditPackageStatus)
	status!: CreditPackageStatus;

	@MapFromDTO()
	bought!: number;

	@MapFromDTO()
	used!: number;

	@Mapper((value: Duration | string) => m.duration(value))
	duration!: Duration;

	@Mapper(momentMapper)
	@Sortable()
	lastUsed?: Moment;

	@Mapper(momentMapper)
	@Sortable()
	activated?: Moment;

	@Mapper(momentMapper)
	@Sortable()
	expired!: Moment | null;

	@Mapper(momentMapper)
	@Sortable()
	completed!: Moment | null;

	@Sortable()
	override created!: Moment | null;

	@Sortable({
		isDefault: true,
		defaultDir: 'desc',
	})
	override modified!: Moment | null;

	@MapFromDTO()
	type = <const>'reports-api';

	constructor(dto?: CreditPackageBaseDto) {
		super(dto);
	}

}

import { DTO, MapFromDTO } from '@bp/shared/models/metadata';
import { CreditPackageBase } from '@bp/shared/domains/credit-packages';

export class MerchantCreditPackage extends CreditPackageBase {

	@MapFromDTO()
	isLast!: boolean;

	constructor(dto?: DTO<MerchantCreditPackage>) {
		super(dto);
	}

}

import { Enumeration } from '@bp/shared/models/core/enum';

export class CreditPackageStatus extends Enumeration {

	static active = new CreditPackageStatus();

	static creditsDepleted = new CreditPackageStatus();

	static pendingActivation = new CreditPackageStatus();

	static expired = new CreditPackageStatus();

	get isActive(): boolean {
		return this === CreditPackageStatus.active;
	}

	get isExpired(): boolean {
		return this === CreditPackageStatus.expired;
	}

	get isDepleted(): boolean {
		return this === CreditPackageStatus.creditsDepleted;
	}

}

import { IPageQueryParamsDTO, ISortQueryParamsDTO } from '@bp/shared/models/common';
import {
	DTO, Mapper, MetadataQueryParamsBase, mixinMetadataPageQueryParams, mixinMetadataSortQueryParams,
	Validator
} from '@bp/shared/models/metadata';
import { Validators } from '@bp/shared/features/validation/models';

import { CreditPackageBase } from './credit-package-base';
import { CreditPackageStatus } from './credit-package-status';

type CreditPackagesQueryParamsDTO = DTO<CreditPackagesQueryParams>
& IPageQueryParamsDTO
& ISortQueryParamsDTO;

export class CreditPackagesQueryParams extends mixinMetadataPageQueryParams(
	mixinMetadataSortQueryParams(MetadataQueryParamsBase, CreditPackageBase),
) {

	@Validator(Validators.uuid)
	id?: string;

	@Validator(Validators.uuid)
	organizationId?: string;

	@Mapper(CreditPackageStatus)
	status?: CreditPackageStatus;

	constructor(dto?: CreditPackagesQueryParamsDTO) {
		super(dto);
	}

}
